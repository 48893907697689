import React, { useEffect } from "react";
import styles from "../style.module.css";
import { RxCrossCircled } from "react-icons/rx";
import calender from "../../../assets/calendarIcon.svg";
import ChangeDate from "../Components/ChangeDate";
import { Spinner } from "@chakra-ui/react";
import { formatDateFromString } from "../../../utils/utils";
const Upcoming = ({ notification = [], loading }) => {
  function formatDate(inputDate) {
    const date = new Date(inputDate);
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };

    // Get the day, month, and year parts from the date
    const day = date.getDate();
    const month = date.toLocaleDateString("en-US", { month: "long" });
    const year = date.getFullYear();

    // Function to add "st", "nd", "rd", or "th" to the day
    function getDayWithSuffix(day) {
      if (day >= 11 && day <= 13) {
        return day + "th";
      }
      switch (day % 10) {
        case 1:
          return day + "st";
        case 2:
          return day + "nd";
        case 3:
          return day + "rd";
        default:
          return day + "th";
      }
    }

    const formattedDate = getDayWithSuffix(day) + " " + month + ", " + year;
    return formattedDate;
  }
  function formatCustomDate(inputDate) {
    const date = new Date(inputDate);

    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    };

    // Function to add "st", "nd", "rd", or "th" to the day
    function getDayWithSuffix(day) {
      if (day >= 11 && day <= 13) {
        return day + "th";
      }
      switch (day % 10) {
        case 1:
          return day + "st";
        case 2:
          return day + "nd";
        case 3:
          return day + "rd";
        default:
          return day + "th";
      }
    }

    // Format the time in 24-hour format (without AM/PM)
    const formattedTime = date.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: false, // Set to false for 24-hour format
    });

    const formattedDate =
      getDayWithSuffix(date.getDate()) +
      " " +
      date.toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      }) +
      " " +
      formattedTime;

    return formattedDate;
  }
  useEffect(() => {
    console.log(notification);
  });
  if (!loading && notification.length === 0) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
        }}
        className="mt-[35%] sm1:mt-[10%]"
      >
        No data available
      </div>
    );
  }
  const handleDelete = (id) => {
    const updatedData = {
      notificationId: id,
      deleted: true,
    };
    // dispatch(updateNotificationData(updatedData)).then(() => {
    //   dispatch(fetchNotificationData(type, "", page));
    //   message.success("Notification updated successfully");
    // });
  };

  return (
    <div className={styles.tableDiv}>
      <table
        className={`flex auto border-2 border-gray-200 ${styles.table} mt-[20%] sm1:mt-0`}
      >
        <div className="w-full h-[300px] md:h-auto  md:max-h-[60vh]  overflow-auto rounded-xl">
          <tr className="flex z-10 relative justify-center w-[240%] items-center md:w-full">
            <th className="w-[17%]">No.</th>
            <th className="w-[17%]">Title</th>
            <th className="w-[17%]">Message Body</th>
            <th className="w-[17%]">Release on</th>
            <th className="w-[17%]">Created Date</th>
            <th className="w-[17%]">Status</th>
            <th className="w-[17%]">Actions</th>
          </tr>

          {loading ? (
            <span className={styles.spin}>
              <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                color="blue.500"
                size="lg"
              />
            </span>
          ) : (
            Array.isArray(notification) &&
            notification.map((el, i) => (
              <tr
                key={i}
                className="h-[50px] cursor-pointer hover:bg-gray-100 transition-all delay-75 ease-out border-t border-gray-200 flex justify-between w-[240%] md:w-full items-center"
              >
                <td className="w-[17%] truncate">{i + 1}</td>
                <td className="w-[17%] truncate">{el.title}</td>
                <td className="w-[17%] truncate">{el.body}</td>

                <td className="w-[17%] truncate">
                  {formatDateFromString(el.releaseDate)},{el.releaseTime}
                </td>
                <td className="w-[17%] truncate">
                  {formatCustomDate(el.createdAt)}
                </td>
                <td className="w-[17%] truncate flex justify-center items-center">
                  <div
                    className={`px-2 py-1 rounded-lg font-bold ${
                      el.status === "Pending" || el.status === "Requested"
                        ? "bg-[#FFF7EF] text-[#E98015]"
                        : ""
                    } ${
                      el.status === "Upcoming" &&
                      "bg-[#41c9b41a] text-[#8CC9E9] px-2"
                    } ${
                      el.status === "Approved" &&
                      "bg-[#41c9b41a] text-[#8CE9DB] px-2"
                    }
                    ${
                      el.status === "Rejected" &&
                      "bg-[#DB3B531A] text-[#DB3B53]"
                    }
                    ${el.status === "Ended" && "text-[#333333] opacity-50"}
                   `}
                  >
                    {el.status}
                  </div>
                </td>
                <td className="w-[17%] truncate">
                  <div className={styles.actionDiv}>
                    <button
                      className={styles.blackBtn3}
                      onClick={() => handleDelete(el._id)}
                    >
                      <RxCrossCircled fontSize={20} /> Cancel
                    </button>
                  </div>
                </td>
              </tr>
            ))
          )}
        </div>
      </table>
    </div>
  );
};

export default Upcoming;
