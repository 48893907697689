import React, { useState } from "react";
import styles from "./style.module.css";
import { Button, Input, Textarea } from "@chakra-ui/react";
import { message } from "antd";

import { db } from "../../../Firebase_config";
import { addDoc, collection, updateDoc } from "firebase/firestore";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { userRequest } from "../../../requestMethod";
// import { fileRequest } from "../../../requestMethod";

const initialState = {
  title: "",
  subject: "",
  Msg: "",
  document: "",
};

const CreateMassage = ({ setCreate }) => {
  const [form, setForm] = useState(initialState);
  const [partner, setPartner] = useState({});
  const navigate = useNavigate();
  const id = JSON.parse(localStorage.getItem("userId"));
  const senderName = JSON.parse(localStorage.getItem("userName"));
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const chatId = queryParams.get("chatId");
  console.log("id", partner);

  useEffect(() => {
    const getData = () => {
      userRequest.get(`/partner/business/getBusiness?_id=${id}`).then((res) => {
        setPartner(res.data.data);
      });
    };
    getData();
  }, [id]);
  const timestamp = new Date().toISOString();

  useEffect(() => {
    const getData = () => {
      userRequest.get(`/partner/business/getBusiness?_id=${id}`).then((res) => {
        setPartner(res.data.data);
      });
    };
    getData();
  }, [id]);
  const addData = async () => {
    const admin_id = "659d3db4c91f0081ad037ac6"; //process.env.REACT_APP_ADMIN_ID;
    if (!form.subject) {
      message.error("Enter subject");
    } else if (!form.Msg) {
      message.error("Enter Message");
    } else {
      setForm(initialState);

      try {
        await addDoc(
          collection(db, `Chats/${id}/chatUser/${admin_id}/${chatId}`),
          {
            Msg: form.Msg,
            sender_name: partner?.name,
            receiver_name: "Admin",

            title: form.title,
            document: form.document ? form.document : false,
            subject: form.subject,
            from: id,
            msgSeen: false,
            timestamp,
          }
        );

        const outboxDocRef = await addDoc(
          collection(db, `outbox/${id}/chatUser`),
          {
            id: chatId,
            sender_name: partner?.name,
            receiver_name: "Admin",
            to: admin_id,
            Msg: form.Msg,
            title: form.title,
            document: form.document ? form.document : false,
            subject: form.subject,
            from: id,
            sender: true,
            receiver: false,
            timestamp,
          }
        );

        // Receiver's inbox
        const inboxDocRef = await addDoc(
          collection(db, `inbox/${admin_id}/chatUser`),
          {
            id: chatId,
            to: admin_id,
            Msg: form.Msg,
            title: form.title,
            sender_name: partner?.name,
            receiver_name: "Admin",
            document: form.document ? form.document : false,
            subject: form.subject,
            from: id,
            sender: true,
            receiver: true,
            timestamp,
          }
        );

        // Now update both the outbox and inbox documents with the document IDs
        await updateDoc(outboxDocRef, {
          outboxDocId: outboxDocRef.id,
          inboxDocId: inboxDocRef.id,
        });

        await updateDoc(inboxDocRef, {
          outboxDocId: outboxDocRef.id,
          inboxDocId: inboxDocRef.id,
        });

        message.success("Message sent to admin successfully");
        navigate("/Messages");
      } catch (error) {
        console.error("Error adding new document: ", error);
        message.error("Failed to send the message. Please try again.");
      }
    }
  };

  // const upload = async (e) => {
  //   const formData = new FormData();
  //   const image = e.target.files[0]; // Get the first selected file from the array

  //   if (image) {
  //     formData.append("file", image);

  //     fileRequest
  //       .post("/api/util/uploadFile", formData)
  //       .then((res) => {
  //         setForm({ ...form, document: res.data.url });
  //         message.success("File uploaded successfully");
  //       })
  //       .catch((err) => {
  //         console.error("Error uploading file:", err);
  //         message.error("File upload failed");
  //       });
  //   }
  // };

  // function getFileNameFromURL(url) {
  //   const parts = url.split("/");
  //   return parts[parts.length - 1];
  // }

  return (
    <div className={styles.MainContainer}>
      <div className={styles.headDiv}></div>
      <div className={`${styles.container1} mt-28 md:mt-10 lg:mt-0`}>
        <p className="font-bold text-xl md:text-base">
          <Link
            to="/Messages"
            className="cursor-pointer text-gray-500 hover:text-gray-800"
          >
            All Messages
          </Link>{" "}
          <span className="mx-2">&gt; </span>
          <span>Create Message</span>
        </p>
        <h1 className="font-bold text-2xl">New Message</h1>

        <div className={styles.resetPassword}>
          <p>Subject*</p>
          <Input
            placeholder="Title (max. 50 characters)"
            value={form.subject}
            onChange={(e) => setForm({ ...form, subject: e.target.value })}
          />
          {/* <p>title*</p>
          <Input
            placeholder="Add Body (max. 150 characters)"
            value={form.title}
            onChange={(e) => setForm({ ...form, title: e.target.value })}
          /> */}
          <p className="mt-5">Message body*</p>
          <Textarea
            placeholder="Add Message (max. 250 characters)"
            rows={5}
            value={form.Msg}
            onChange={(e) => setForm({ ...form, Msg: e.target.value })}
          />
          {/* <p>Attach files</p>
          <span className={styles.attachSpan}>
            <p>{getFileNameFromURL(form.document)}</p>
            <label htmlFor="main" className="cursor-pointer">
              <ImAttachment />
            </label>
            <input
              type="file"
              id="main"
              onChange={(e) => upload(e)}
              style={{ display: "none" }}
            />
          </span> */}
        </div>
      </div>
      <div className={styles.btn}>
        <Button
          colorScheme="black"
          variant="outline"
          w="127px"
          onClick={() => navigate("/Messages")}
        >
          Cancel
        </Button>
        <Button
          bg="black"
          color="#fff"
          variant="solid"
          w="147px"
          onClick={addData}
        >
          Send Message
        </Button>
      </div>
    </div>
  );
};

export default CreateMassage;
