import React, { useEffect, useState, forwardRef } from "react";
import styles from "./styles.module.css";
import { Button, Input, Radio, RadioGroup, Stack, Box } from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { message } from "antd";
import UploadImage from "./Upload";
import suggestionList from "../../../../assets/suggestionListT.png";
import pizza from "../../../../assets/hamburger.jpg";
import inFeedList from "../../../../assets/feedT.png";
import Ad from "../../../../assets/ad.png";
import Cross from "../../../../assets/cross.png";
import { createPartnerAdData } from "../../../../Redux/Advertisement/Partner/PartnerAdReducer";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { adjustedDate } from "../../../../utils/utils";
import { IoLocationSharp } from "react-icons/io5";

const initialState = {
  name: "",
  partnerId: "",
  image: "",
  title: "",
  body: "",
  location: "",
  cities: [],
  releaseDate: "",
  releaseTime: "",
  adType: "Suggestion",
  userType: "PARTNER",
};

const CreateAd = ({ setCreateAd, data, initialPhoto }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [fileList, setFileList] = useState([]);

  const [form, setForm] = useState(initialState);
  const [selectedDate, setSelectedDate] = useState(null);

  const getMinDate = () => {
    const date = new Date();
    date.setDate(date.getDate() + 1);
    return date;
  };

  const minDate = getMinDate();

  const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <Input
      value={value}
      onClick={onClick}
      placeholder="DD-MM-YYYY"
      style={{ cursor: "pointer", marginBottom: "30px" }}
      ref={ref}
    />
  ));
  const handleDateChange = (date) => {
    const originDate = adjustedDate(date);
    setSelectedDate(originDate);
    setForm({ ...form, releaseDate: originDate.toISOString().split("T")[0] });
  };

  const [previewImage, setPreviewImage] = useState("");

  useEffect(() => {
    // userRequest.get(`admin/partner/getAllPartners`).then((res) => {
    //     setPartners(res.data.data)
    // })
  }, []);
  const addData = () => {
    if (!form.name) {
      message.error("Enter Campaign Name");
      return;
    }

    if (form.adType !== "Suggestion") {
      if (!form.title) {
        message.error("Enter Ad Name");
        return;
      }
      if (!form.body) {
        message.error("Enter body");
        return;
      }
    }

    if (!form.cities) {
      message.error("Enter cities");
      return;
    }

    if (!form.releaseDate) {
      message.error("Enter releaseDate");
      return;
    }

    // If all validations pass, call the API
    setCreateAd(false);
    dispatch(createPartnerAdData(form))
      .then(() => {
        setForm(initialState);
        setPreviewImage("");
        setFileList([]);
        message.success("Advertisement created successfully");
        // setTimeout(() => {
        //   setCreateAd(false);
        //   window.location.reload();
        // }, 2000);
      })
      .catch((error) => {
        message.error(`Error: ${error.message}`);
      });
  };
  const handleImageUpload = async (imageUrl) => {
    setForm({ ...form, image: imageUrl });
  };

  const addCity = () => {
    if (form.city && !form.cities.includes(form.city)) {
      setForm((prevForm) => ({
        ...prevForm,
        cities: [...prevForm.cities, prevForm.city],
        city: "",
      }));
    }
  };

  const removeCity = (removedCity) => {
    setForm({
      ...form,
      cities: form.cities.filter((city) => city !== removedCity),
    });
  };
  return (
    <div className={styles.MainContainer}>
      <div className={styles.headDiv}></div>
      <div className={`${styles.container1} xl:mt-0`}>
        <p className="font-bold text-lg md:text-base">
          <a
            href="/Partner"
            className="cursor-pointer text-gray-500 hover:text-gray-800"
          >
            Partner Ads
          </a>{" "}
          <span className="mx-2">&gt; </span>
          <span>Create an Advertisement</span>
        </p>
        <h1 className="font-bold text-3xl">Create an Ad</h1>
        <div className={styles.resetPassword}>
          <RadioGroup
            value={form.adType}
            onChange={(value) => setForm({ ...form, adType: value })}
          >
            <Stack direction="column">
              <Radio value="Suggestion">
                <p className={styles.radioP}>Suggestion List (choose a city)</p>
              </Radio>
              <Radio value="Feed">
                <p className={styles.radioP}>
                  In-feed Ad (location-based + 10 km radius)
                </p>
              </Radio>
            </Stack>
          </RadioGroup>

          <p className="mt-4">Campaign Name*</p>
          <Input
            placeholder="Campaign Name (max. 50 characters)"
            value={form.name}
            onChange={(e) => setForm({ ...form, name: e.target.value })}
          />
          {form.adType === "Feed" && (
            <>
              <p className="mt-2">Ad Title*</p>
              <Input
                placeholder="Ad Title "
                value={form.title}
                onChange={(e) => setForm({ ...form, title: e.target.value })}
              />
            </>
          )}
          {form.adType === "Feed" && (
            <>
              <p lassName="mt-3">Ad Body*</p>
              <textarea
                placeholder="Ad Body (max. 150 characters)"
                rows={4}
                value={form.body}
                onChange={(e) =>
                  setForm({ ...form, body: e.target.value.slice(0, 150) })
                }
              />
            </>
          )}
          <UploadImage
            setFileList={setFileList}
            fileList={fileList}
            setPreviewImage={setPreviewImage}
            previewImage={previewImage}
            onImageUpload={handleImageUpload}
          />

          {/* {previewImage && (
            <div>
              <img
                src={previewImage}
                alt="Uploaded"
                style={{ maxWidth: "100%", maxHeight: "400px" }}
              />
            </div>
          )} */}
          <p className={styles.longString}>
            Please verify that your uploaded file compiles with our system{" "}
            <br></br>
            requirements for{" "}
            <Link
              className="underline"
              to="https://www.netme.eu/terms-conditions/"
              target="_blank"
            >
              ads campaign
            </Link>
          </p>
          <div></div>
          <p>Only Received by users in the following city:</p>
          <div className={styles.citydiv}>
            {form.adType === "Feed" ? (
              <input
                placeholder="City"
                type="text"
                value={data.city}
                readOnly
                className="pl-3"
              />
            ) : (
              <>
                <div className={styles.scrollstack}>
                  <Stack direction="row" spacing={2} align="center">
                    {form.cities.map((city) => (
                      <div
                        key={city}
                        className={`${styles.selectedCity} flex items-center gap-1 mb-2`}
                      >
                        <p>{city}</p>
                        <p
                          onClick={() => removeCity(city)}
                          className="text-red-700 font-semibold text-sm cursor-pointer"
                        >
                          X
                        </p>
                      </div>
                    ))}
                  </Stack>
                </div>
                <input
                  placeholder="City"
                  type="text"
                  value={form.city}
                  onChange={(e) => setForm({ ...form, city: e.target.value })}
                  onKeyDown={(e) => e.key === "Enter" && addCity()}
                  onBlur={addCity}
                  className="pl-3"
                />
              </>
            )}
          </div>
          <p>Release on:</p>
          {/* <Input
            type="date"
            value={form.releaseDate}
            onChange={(e) => setForm({ ...form, releaseDate: e.target.value })}
            className="mb-20"
            min={minDate}
          /> */}
          <Box>
            <DatePicker
              selected={selectedDate}
              onChange={handleDateChange}
              customInput={<CustomInput />}
              minDate={minDate}
              dateFormat="dd-MM-yyyy"
              popperModifiers={[
                {
                  name: "arrow",
                  options: {
                    padding: ({ popper, reference, placement }) => ({
                      right: Math.min(popper.width, reference.width) - 24,
                    }),
                  },
                },
              ]}
            />
          </Box>
          {/* <p>Release time:</p>
          <Input
            type="time"
            value={form.releaseTime}
            onChange={(e) => setForm({ ...form, releaseTime: e.target.value })}
          /> */}
        </div>
      </div>
      <div className={styles.container2}>
        <div className={`${styles.btn} hidden md:flex`}>
          <Button
            colorScheme="black"
            variant="outline"
            w="127px"
            onClick={() => setCreateAd(false)}
          >
            Cancel
          </Button>
          <Button
            bg="black"
            color="#fff"
            variant="solid"
            w="127px"
            onClick={addData}
          >
            Save
          </Button>
        </div>
        {form.adType === "Suggestion" && (
          <div className={styles.imageContainer}>
            <p className="text-center my-3 text-1xl font-bold">
              Preview Suggestion List - Ad
            </p>
            <img
              src={suggestionList}
              alt="Suggestion List"
              className={styles.suggestionImage}
            />
            <div className="flex justify-center">
              <p className="text-center px-3 md:px-0 font-bold text-md mt-2 mb-5 italic max-w-[450px]">
                Please note that it can take up to 24 hours. for your ad to be
                approved.
              </p>
            </div>
            <div className={styles.imageInside}>
              <div className="">
                <div className={`${styles.previewText} flex flex-col`}>
                  {/* <div className="relative right-4 mt-3"> */}
                  <p className={styles.para1}>Here are some </p>
                  <p className={styles.para1}>suggestions for you !</p>
                  <p className="mb-3">Discover these super tasty places!</p>
                  {/* </div> */}
                </div>
                <div>
                  <div className={styles.previewInputImage}>
                    <img
                      src={previewImage ? previewImage : initialPhoto}
                      className={`w-[550px] h-[160px] md:max-w-[550px] md:max-h-[340px] object-cover rounded-[25px] mb-3 ${styles.sugImage}`}
                      alt="Uploaded"
                    />
                  </div>
                  <div
                    className={`${styles.adPreviewTitleLocation} flex items-center justify-between `}
                  >
                    {/* <div
                    className={` flex items-center justify-between ${styles.adPreviewTitle}`}
                  > */}
                    <p className={styles.adPreviewTitle}>{data.name}</p>
                    <div
                      className={`${styles.adPreviewLocation} flex items-center gap-1`}
                    >
                      <IoLocationSharp />
                      <p>2km</p>
                    </div>
                  </div>

                  {/* </div> */}
                </div>

                <div style={{ marginTop: "10px" }}>
                  <div className={`${styles.previewInputImage} `}>
                    <img
                      src={pizza}
                      alt="1"
                      className={`w-[550px] h-[160px] md:max-w-[550px] md:max-h-[340px] object-cover rounded-[25px] mb-3 ${styles.sugImage}`}
                    />
                  </div>
                  <div
                    className={`${styles.adPreviewTitleLocation} flex items-center justify-between `}
                  >
                    {/* <div
                    className={` flex items-center justify-between ${styles.adPreviewTitle}`}
                  > */}
                    <p className={styles.adPreviewTitle}>Burger House</p>
                    <div
                      className={`${styles.adPreviewLocation} flex items-center gap-1`}
                    >
                      <IoLocationSharp />
                      <p>2km</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {form.adType === "Feed" && (
          <div className={styles.imageContainer1}>
            <p className="text-center my-3 text-1xl font-bold">
              Preview In-Feed - Ad
            </p>
            <img
              src={inFeedList}
              alt="inFeedList"
              className={styles.suggestionImage1}
            />
            <div className="flex justify-center">
              <p className="text-center px-3 md:px-0 font-bold text-md mt-2 mb-5 italic max-w-[450px]">
                Please note that it can take up to 24 hours. for your ad to be
                approved.
              </p>
            </div>
            <div className={styles.imageInside1}>
              <div>
                <div className={styles.previewInputImage1}>
                  <div className={styles.div1}>
                    <img
                      src={Ad}
                      alt="1"
                      style={{
                        width: "30px",
                        position: "absolute",
                        top: "4%",
                        left: "-3%",
                      }}
                    />
                    <img
                      src={Cross}
                      alt="1"
                      style={{
                        width: "30px",
                        position: "absolute",
                        right: "4%",
                        top: "4%",
                      }}
                    />
                    <img
                      src={previewImage ? previewImage : initialPhoto}
                      alt="1"
                      className="rounded-[25px] object-cover h-[150px] "
                    />

                    <div className={styles.div2}>
                      <p className={styles.paragraph}>{form.title}</p>
                      <p className={styles.paragraph1}>{form.body}</p>
                    </div>
                    <div className={styles.div3}>
                      <button className={styles.buttons1}>
                        All Localities
                      </button>
                      <button className={styles.buttons2}>More Details</button>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ marginTop: "10px" }}></div>
            </div>
          </div>
        )}
      </div>
      <div className={`${styles.btn} flex justify-center mb-16 md:hidden`}>
        <Button
          bg="black"
          color="#fff"
          variant="solid"
          w="127px"
          onClick={addData}
        >
          Save
        </Button>
        <Button
          colorScheme="black"
          variant="outline"
          w="127px"
          onClick={() => setCreateAd(false)}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default CreateAd;
